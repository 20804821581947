<template>
  <v-app id="app" v-cloak>
    <!-- FONDAMENTALE O NON TI FUNZIONANO DIALOGS, SWITCH, ROBA JS -->
    <div dense light v-cloak style="position: fixed; width: 100%; z-index: 920">
      <!-- HEADER contiene LOGHI MENU AR -->
      <v-content v-cloak>
        <header>
          <div class="header d-flex align-center" style="margin-top: 48px" @click="manageBtnHeader('all')" v-cloak>
            <div class="container" style="display: block">
              <div class="header__logos">
                <router-link :to="routerLogo()" class="header__logos__site-logo router-link-exact-active router-link-active">
                  <img src="images/bibl_riv_new.png" alt="Logo BIBLIOTECA RIVISTE" />
                </router-link>
                <span>Modulo della banca dati editoriale DeJure</span>
              </div>

              <!-- NAV in versione smartphone il menu diventa a tutta larghezza -->
              <ul class="header__nav" v-show="mobileOn" style="margin-top: 25px">
                <a @click="mobileOn = false" class="close-menu">
                  <span class="icon-close"></span>
                </a>
                <li
                  v-show="false"
                  :class="[
                    { header__nav__item: true },
                    {
                      'header__nav__item--active': currentRoute('/') || currentRouteType('home')
                    }
                  ]"
                  @click="routeCheckMobile()">
                  <router-link :to="'/'" title="Home">Home</router-link>
                </li>
                <li
                  :class="[
                    { header__nav__item: true },
                    {
                      'header__nav__item--active': currentRoute('/catalogo') || currentRouteType('catalogo') || fromCatalogue()
                    }
                  ]"
                  @click="routeCheckMobile()">
                  <router-link :to="'/catalogo'" title="Il Catalogo">Il Catalogo</router-link>
                </li>
                <li
                  :class="[
                    { header__nav__item: true },
                    {
                      'header__nav__item--active': currentRoute('/riviste') || currentRouteType('riviste') || fromVolumes()
                    }
                  ]"
                  @click="routeCheckMobile()">
                  <router-link :to="'/riviste'" title="Le mie riviste">Le mie riviste</router-link>
                </li>
              </ul>

              <!-- ICON BAR -->
              <div class="header__icon-bar" style="float: right">
                <a class="header__icon-bar__mobile-nav" @click="mobileOn = true">
                  <span></span>
                  <span></span>
                  <span></span>
                </a>

                <a v-if="settings && settings.marketing" :href="settings.marketing.url_faq" target="_blank" title="Leggi le FAQ" class="header__icon-bar__private">
                  <img src="images/question-solid.svg" alt="Icona FAQ" />
                  <span>FAQ</span>
                </a>
              </div>

              <a v-if="settings && settings.marketing" :href="settings.marketing.url_faq" target="_blank" title="Leggi le FAQ" class="header__icon-bar__private fullw">
                <img src="images/question-solid.svg" alt="Icona FAQ" />
                <span style="font-family: sans-serif; font-weight: 400; font-size: 10pt">FAQ</span>
              </a>
            </div>
          </div>
        </header>
      </v-content>
    </div>

    <v-snackbar v-model="showModalError" :timeout="10000">
      {{ modalMessage }}
      <v-btn color="error" text @click="showModalError = false" icon>
        <v-icon small>close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-overlay :value="loading" style="z-index: 999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div @click="manageBtnHeader('all')">
      <router-view
        class="container__margin"
        :authorization="authorization"
        :overlay="loading"
        :settings="settings"
        @authorize="authorize"
        @checkQSTicket="checkQSTicket"
        :key="token" />
      <!-- :key="'route_' + currentKey" -->
    </div>
  </v-app>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'
  import { mapState } from 'vuex'
  //import StaticHeader from "./components/Header.vue"
  /*components:{
    StaticHeader: StaticHeader
  },*/
  export default {
    data: (vm) => ({
      loading: true,
      ticket: null,
      settings: {},
      modalMessage: '',
      currentKey: '',
      logoutDrop: false,
      showOnExitPopup: false,
      logged: false,
      modalExpired: false,
      mobileOn: true,
      goToVolumes: false,
      btn_header_gfl: false,
      btn_header_network: false,
      btn_header_profile: false,
      url_ticket: '',
      hf_ticket: null,
      authorization: {
        profile: {
          userId: ''
        },
        codice_volume: ''
      },
      qsTicket: false,
      token: 'null'
    }),
    computed: {
      ...mapState(['config'])
    },
    watch: {
      async $route(to, from) {
        var self = this
        window.scroll(0, 0)
        //if (window.innerWidth <= 767) this.mobileOn = false;
        var url_parameters = this.$route.query

        if (url_parameters.ticket) {
          //verifico se il ticket è valido
          this.qsTicket = true
          await this.authorize(url_parameters.ticket, false)
          //console.log("fine authorize");
        } else {
          if (localStorage.token_biblio_riv) {
            //se è loggato
            await this.authorize(localStorage.token_biblio_riv, false)
          } else {
            this.loading = false
            //this.checkIfExpired();
            this.logged = false
            localStorage.token_biblio_riv_jwt = self.settings.free.jwt
            self.token = self.settings.free.jwt
            self.settings.freeToken = true
            localStorage.dateSavedMaterie = 1555220595
            self.removeChat()
            self.refreshComponent(false)
          }
        }
      }
    },
    created() {
      this.settings = this.config
      if (localStorage.token_biblio_riv) {
        this.url_ticket = '?ticket=' + localStorage.token_biblio_riv
      } else this.url_ticket = ''
    },
    async mounted() {
      var self = this
      window.addEventListener('message', (event) => {
        /*if (event.data.task === "returnonlyticket") {
          var token = event.data.params.ticket;
          self.authorize(token, true);
        }*/
        if (event.data.task === 'gethfticket') {
          var token = event.data.params.ticket

          if (token && !self.hf_ticket) {
            self.hf_ticket = token
            self.loading = true
            self.authorize(token, true)
          } else if (!token && localStorage.token_biblio_riv != 'null') {
            self.logout()
          }
        }
      })

      if (window.innerWidth <= 768) {
        this.showOnExitPopup = true
        this.mobileOn = false
      }
      var url_parameters = this.$route.query

      if (url_parameters.ticket) {
        //verifico se il ticket è valido
        await this.authorize(url_parameters.ticket, false)
        //console.log("fine authorize");
      } else {
        if (localStorage.token_biblio_riv) {
          //se è loggato
          await this.authorize(localStorage.token_biblio_riv, false)
        } else {
          this.loading = false
          //this.checkIfExpired();
          this.logged = false
          localStorage.token_biblio_riv_jwt = self.settings.free.jwt
          self.token = self.settings.free.jwt
          self.settings.freeToken = true
          localStorage.dateSavedMaterie = 1555220595
          self.removeChat()
        }
      }
    },

    methods: {
      moment() {
        return moment()
      },
      logout() {
        localStorage.token_biblio_riv = null
        localStorage.token_biblio_riv_jwt = null
        localStorage.LHinfo_riviste = ''
        localStorage.LHnick_riviste = ''
        localStorage.LHurl_riviste = ''

        this.token = 'null'
        this.logged = false

        this.$gtm.trackEvent({
          target: 'auth', // Event type [default = 'interaction'] (Optional)
          action: 'logout',
          crmId: this.authorization.profile.crmId,
          session: this.createSessionTimestamp()
        })

        this.authorization = {
          profile: {
            userId: ''
          },
          codice_volume: ''
        }

        this.url_ticket = ''
        this.hf_ticket = null
        setTimeout(() => {
          location.href = location.origin //PBR-379, rimuovo eventuali qs
        }, 200)

        /*this.$store.dispatch('setAuth', {
          sso: null,
          jwt: null
        })*/

        //this.$router.push({ name: "Home" }).catch((err) => {});
        /*setTimeout(() => {
          window.location.href = '/'
        }, 300)*/
        //injectRootChat(false);
      },
      routeCheckMobile() {
        if (window.innerWidth <= 768) {
          this.mobileOn = false
        } else {
          this.mobileOn = true
        }
      },
      authorize(ticket, isLogin) {
        var env = this.settings.login_prodotto ? this.settings.login_prodotto : 'bibliotecariviste'
        if (!ticket && !this.logged) showLoginDialog(env, '', '', '', '', '', '', '', '')
        if (isLogin && ticket) {
          this.ticket = ticket
          //
          var body = { ticket: ticket }
          var header = null
          this.goToVolumes = true
          this.REQUEST(`${this.settings.configuration.apiURL}/auth`, header, 'POST', 'authorize', body) //login e validità di 3h
          //this.$router.push({ path: "/imieivolumi" }).catch(err => {});
        } else if (ticket) {
          this.ticket = ticket
          this.mantainTicket()
          //verifico che sia valido il ticket
          var body = { ticket: ticket }
          var header = null
          this.goToVolumes = false
          this.REQUEST(`${this.settings.configuration.apiURL}/auth`, header, 'POST', 'authorize', body) //login e validità di 3h
        } else {
          /*this.showModalError = true;
        this.modalMessage = "Ticket mancante o non valido";*/
        }
      },
      currentRoute(route) {
        if (this.$route.path == route) return true
        else return false
      },
      currentRouteType(where) {
        //sono nel dettaglio, per capire se sottolineare volumi o unlimited, devo verificare dove sto
        if (this.$route.path == '/details') {
          if (where == 'catalogo') {
            if (this.$route.query.from && decodeURIComponent(this.$route.query.from) == 'Tutto il Catalogo') return true
          } else if (where == 'riviste') {
            if (this.$route.query.from && decodeURIComponent(this.$route.query.from) == 'Le mie riviste') return true
          } else if (where == 'unlimited') {
            if (this.$route.query.from && decodeURIComponent(this.$route.query.from) == 'Unlimited') return true
          } else return false
        } else return false
      },
      checkQSTicket(qs) {
        if (qs && qs.ticket) {
          this.ticket = qs.ticket
          localStorage.token_biblio_riv = qs.ticket
          this.mantainTicket()
        } else {
          //this.ticket = "";
          //this.authorize(false, false);
        }
      },
      mantainTicket() {
        if (this.ticket) {
          return '?ticket=' + this.ticket
        } else {
          return ''
        }
      },
      routerLogo() {
        if (this.authorization.profile.userId) return '/riviste'
        else return '/'
      },
      manageBtnHeader(type) {
        //gestione show/hide dropdown wrapper
        if (type == 'all') {
          this.btn_header_profile = false
          this.btn_header_network = false
          this.btn_header_gfl = false
        }
        if (type == 'gfl') {
          this.btn_header_gfl = !this.btn_header_gfl
          this.btn_header_network = false
          this.btn_header_profile = false
        }
        if (type == 'network') {
          this.btn_header_network = !this.btn_header_network
          this.btn_header_gfl = false
          this.btn_header_profile = false
        }
        if (type == 'profile') {
          if (this.authorization.profile.customerName) {
            this.btn_header_profile = !this.btn_header_profile
            this.btn_header_network = false
            this.btn_header_gfl = false
          } else {
            this.btn_header_profile = false
            this.btn_header_network = false
            this.btn_header_gfl = false
            this.authorize(null, true)
            this.logoutDrop = !this.logoutDrop
          }
        }
      },
      fromCatalogue() {
        if (this.settings.catalogo && this.settings.catalogo.filters && !this.settings.catalogo.filters.fromRiviste && this.currentRoute('/results')) return true
        else return false
      },
      fromVolumes() {
        if (this.settings.catalogo && this.settings.catalogo.filters && this.settings.catalogo.filters.fromRiviste && this.currentRoute('/results')) return true
        else return false
      },
      createSessionTimestamp() {
        var d = new Date()
        var session = d.getFullYear().toString() + d.getMonth().toString() + d.getDate().toString() + d.getHours() + d.getMinutes() + d.getSeconds()
        return session
      },
      fromUnlimited() {
        if (this.settings.catalogo && this.settings.catalogo.filters && this.settings.catalogo.filters.fromUnlimited && this.currentRoute('/results')) return true
        else return false
      },
      injectChat() {
        var self = this
        console.log(this.authorization.profile)
        var LHinfo =
          self.authorization && self.authorization.profile
            ? self.authorization.profile.contactEmail + ' - ' + self.authorization.profile.crmId + ' - ' + self.authorization.profile.customerName
            : 'null'
        var LHnick = self.authorization && self.authorization.profile.crmId ? self.authorization.profile.crmId : 'null'
        var LHurl = window.location.href

        localStorage.LHinfo_riviste = LHinfo
        localStorage.LHnick_riviste = LHnick
        localStorage.LHurl_riviste = LHurl
        localStorage.LHreload = true
        if (this.$route.name != 'details' && this.$route.name != 'Rivista' && this.$route.name != 'News') this.$router.push({ name: 'Riviste' })
        //
        let iframe = document.getElementById('chat-iframe')
        iframe.contentWindow.location.reload()
      },
      removeChat() {
        var c = document.getElementById('chatScript')
        if (c) c[0].parentElement.removeChild(c[0])
      },
      checkIfExpired() {
        var body = { ticket: localStorage.token_biblio_riv }
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }
        var url = `${this.settings.configuration.apiURL}/sso/get_time_left`
        this.REQUEST(url, header, 'POST', 'validateToken', body)
      },
      rndStr(len) {
        let text = ' '
        let chars = 'abcdefghijklmnopqrstuvwxyz'
        for (let i = 0; i < len; i++) {
          text += chars.charAt(Math.floor(Math.random() * chars.length))
        }
        return text
      },
      refreshComponent(loading) {
        this.loading = loading
        this.currentKey = this.rndStr(8)
        this.$forceUpdate()
      },
      getCookie(cname) {
        var name = cname + '='
        var decodedCookie = decodeURIComponent(document.cookie)
        var ca = decodedCookie.split(';')
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i]
          while (c.charAt(0) == ' ') {
            c = c.substring(1)
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
          }
        }
        return ''
      },
      checkCookie() {
        if (this.getCookie('biblioteca_riviste_popup')) {
        } else {
          var linkk = '#'
          if (this.settings.marketing && this.settings.marketing.url_form_agenti) {
            linkk = this.settings.marketing.url_form_agenti
          }
          Swal.fire({
            icon: 'info',
            title: 'Benvenuto!',
            html:
              "Benvenuto nella nuova Biblioteca Riviste. Puoi effettuare una ricerca su tutto il catalogo GFL e consultare le riviste da te acquistate. Un patrimonio documentale inestimabile, a portata di clic. <br> <a href='" +
              linkk +
              "' target='_blank' >Per maggiori informazioni rivolgiti al tuo agente di fiducia</a>"
          })
          document.cookie = 'biblioteca_riviste_popup=true; expires=Fri, 31 Dec 9999 23:59:59 GMT'
        }
      },
      async REQUEST(call, header, method, type, body) {
        var self = this
        //this.loading = true;
        if (method == 'POST') {
          await axios
            .post(call, body, { headers: header })
            .then((response) => {
              switch (type) {
                case 'authorize':
                  let reload = false
                  console.log('in corso auth')
                  self.loading = false

                  if (this.qsTicket && !this.$route.query.norefresh) {
                    //se non ero loggato e mi è stato passato il token in url, reloddo
                    reload = true
                  }

                  self.authorization = response.data
                  localStorage.token_biblio_riv = body.ticket
                  localStorage.token_biblio_riv_jwt = response.data.jwt

                  this.$store.dispatch('setAuth', response.data.jwt)
                  this.$store.dispatch('setProfile', response.data.profile)

                  this.$store.dispatch('setAuth', {
                    sso: body.ticket,
                    jwt: response.data.jwt
                  })

                  if (localStorage.token_biblio_riv) {
                    self.url_ticket = '?ticket=' + body.ticket
                  } else self.url_ticket = ''

                  self.ticket = body.ticket
                  self.logged = true
                  self.settings.freeToken = false
                  //inject chat

                  self.loading = false
                  /*setInterval(() => {
                    self.checkIfExpired()
                  }, configuration.timeoutCheckExpiration)*/ //GESTITO DA HF SSO
                  self.logoutDrop = false

                  self.settings.goToVolumes = self.goToVolumes
                  self.refreshComponent(false)

                  if (self.goToVolumes) {
                    var roless = response.data.profile.roles.map((o) => o.pos).join('|')
                    self.authorization.profile.roless = roless
                    self.$gtm.trackEvent({
                      target: 'auth', // Event type [default = 'interaction'] (Optional)
                      action: 'login',
                      crmId: response.data.profile.crmId,
                      roles: roless,
                      session: self.createSessionTimestamp()
                    })
                  }

                  if (!localStorage.LHinfo_riviste) {
                    self.injectChat()
                  }

                  setTimeout(function () {
                    self.checkCookie()
                  }, 1000)

                  if (reload) {
                    //debugger
                    this.qsTicket = false
                    let queries = { ...this.$route.query, norefresh: true }
                    this.$router.push({ name: this.$route.name, query: queries }).catch((err) => {})
                    location.reload()
                  } else {
                    //debugger
                    let queries = { ...this.$route.query }
                    delete queries.norefresh
                    //this.$router.push({ name: this.$route.name, query: queries }).catch(err => {})
                  }

                  break
                case 'validateToken':
                  if (response.data.maxtime <= 0) {
                    /*self.showModalError = true;
                  self.modalMessage =
                    "Ticket SSO scaduto o non valido. Effettuare il login";*/
                    //localStorage.token_biblio_riv = null;
                    localStorage.token_biblio_riv_jwt = self.settings.free.jwt
                    self.token = self.settings.free.jwt
                    self.settings.freeToken = true
                    localStorage.dateSavedMaterie = 1555220595
                    self.removeChat()
                    self.refreshComponent(false)
                  } else {
                    //self.settings.freeToken = true;
                    //console.log("Token ancora valido alle: " + new Date().toLocaleString());
                  }
                  break
              }
            })
            .catch(function (error) {
              self.loading = false
              if (type == 'validateToken') {
                //localStorage.token_biblio_riv = null;
                localStorage.token_biblio_riv_jwt = self.settings.free.jwt

                localStorage.LHinfo_riviste = ''
                localStorage.LHnick_riviste = ''
                localStorage.LHurl_riviste = ''

                self.token = self.settings.free.jwt
                self.settings.freeToken = true
                self.removeChat()
                self.refreshComponent(false)
                Swal.fire({
                  icon: 'error',
                  title: 'Errore',
                  text: 'Sessione scaduta! '
                })
              }
              //if (type == "authorize") {
              else {
                self.removeChat()
                localStorage.token_biblio_riv_jwt = self.settings.free.jwt
                self.token = self.settings.free.jwt
                self.settings.freeToken = true
                self.url_ticket = ''
              }
            })
        } else {
          await axios
            .get(call, header)
            .then(function (response) {
              switch (type) {
                case 'loadSummary':
                  self.pdf = response.data
                  break
              }
              self.refreshComponent(false)
            })
            .catch(function (error) {
              self.showModalError = true
              self.modalMessage = 'Errore di autenticazione'
              self.refreshComponent(false)
            })
        }
      }
    }
  }
</script>

<style>
  div#hfmastercollector {
    top: 0;
    position: fixed;
    z-index: 999;
    width: 100%;
  }
  .swal2-container.swal2-center.swal2-backdrop-show {
    z-index: 9999;
  }

  .v-input__slot {
    caret-color: black !important;
  }

  div#modalBase {
    z-index: 9998 !important;
  }
  a#chiudilogf {
    margin-top: 114px !important;
    margin-left: 315px !important;
  }
  .v-toolbar__content,
  header.v-sheet.v-sheet--tile.theme--light.v-toolbar.v-toolbar--dense.v-app-bar {
    height: auto !important;
    position: fixed;
    z-index: 9996;
    width: 100%;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 0px;
  }
  .mobileView.v-list.v-sheet.v-sheet--tile.theme--light {
    margin-top: 15px;
  }
  .mobileView.v-list.v-sheet.v-sheet--tile.theme--light * {
    color: black;
    text-decoration: none;
    font-weight: 600;
    text-align: right;
    text-transform: uppercase;
  }
  .v-application--wrap {
    min-height: unset;
  }
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;
  }

  .v-menu__content.theme--light.v-autocomplete__content {
    z-index: 9999 !important;
  }

  .search__caption__copy__searchbox .cta.orange {
    display: block;
    font-size: 10pt;
  }

  i.v-icon.notranslate.mdi.mdi-menu-down.theme--light.primary--text {
    cursor: pointer !important;
  }

  #LH2013 {
    bottom: -7px !important;
  }

  .search__caption__copy__searchbox input {
    width: 50% !important;
    background: #fff !important;
  }
  .v-input--dense > .v-input__control > .v-input__slot {
    background: white !important;
  }
  .ctlg {
    width: 50%;
    display: inline-flex;
  }
  @media (max-width: 767px) {
    .ctlg {
      width: 100% !important;
    }
  }

  #nav {
    padding: 30px;
  }
  a {
    font-weight: bold;
    color: #888;
    text-decoration: none;
  }
  .router-link-exact-active {
    color: #42b983;
  }

  /**/

  .fa-linkedin:before {
    content: '\f0e1';
    color: #fff !important;
    position: absolute;
    margin-top: 9px;
    margin-left: 11px;
  }

  .fa-facebook-f:before,
  .fa-facebook:before {
    content: '\f09a';
    color: #fff !important;
    position: absolute;
    margin-top: 10px;
    margin-left: 13px;
  }

  .fa-twitter:before {
    content: '\f099';
    color: #fff !important;
    position: absolute;
    margin-top: 10px;
    margin-left: 11px;
  }

  .fa-instagram:before {
    content: '\f16d';
    color: #fff !important;
    position: absolute;
    margin-top: 10px;
    margin-left: 11px;
  }

  .fa-youtube-play:before {
    content: '\f16d';
    color: #fff !important;
    position: absolute;
    margin-top: 10px;
    margin-left: 9px;
  }

  #footer-esempio.footer-ghp {
    padding: 0px;
    margin: 0px;
  }
  #footer-esempio .footer-top-ghp {
    background: #133371;
    padding: 35px 0;
  }
  #footer-esempio .footer-top-in-ghp {
    padding: 0px;
  }
  #footer-esempio .footer-part-ghp {
    float: left;
  }
  #footer-esempio .footer-title-ghp {
    padding-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
  }
  #footer-esempio .footer-links-ghp ul li {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
  }
  #footer-esempio .footer-links-ghp ul li a {
    color: #ffffff;
  }
  #footer-esempio .footer-email-input-ghp {
    padding-bottom: 10px;
  }
  #footer-esempio .footer-email-input-ghp input {
    min-width: 255px;
    height: 34px;
    border: 1px solid #848888;
    background: #fff;
    color: #848888;
    font-weight: 700;
    font-size: 15px;
    padding: 0 20px;
  }
  #footer-esempio .footer-email-btn-ghp .common-blue-btn-hp {
    max-width: 115px;
    height: 35px;
    line-height: 35px;
  }
  #footer-esempio .common-blue-btn-hp {
    max-width: 214px;
    padding: 0 15px;
    height: 43px;
    line-height: 43px;
    background: #00a6de;
    font-weight: 700;
    color: #fff;
    text-align: center;
    font-size: 17px;
    text-transform: uppercase;
    display: block;
    border: 0px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  #footer-esempio .footer-follow-ghp ul li {
    float: left;
    margin-right: 10px;
  }
  #footer-esempio .footer-follow-ghp ul li img {
    width: 37px;
  }
  #footer-esempio .footer-bottom-ghp {
    background: #395488;
    padding: 20px 0;
  }
  #footer-esempio .footer-logo-ghp {
    float: left;
    width: 200px;
  }
  #footer-esempio .footer-logo-ghp img {
    width: 100px;
  }
  #footer-esempio .footer-right-ghp {
    float: right;
    width: calc(100% - 200px);
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    font-weight: 700;
    padding: 8px 0 0 0;
  }
  #footer-esempio .footer-right-ghp a {
    color: #ffffff;
  }
  #footer-esempio .footer-links-ghp {
    text-align: left;
  }
  #footer-esempio ul li {
    list-style-type: none;
  }
  .fa-facebook:before,
  .fa-twitter:before,
  .fa-instagram:before,
  .fa-linkedin:before,
  .fa-youtube-play:before {
    color: white;
  }
  .footer-codice-issn {
    background: #dbe0e9;
    font-size: 14px;
    color: #133371;
    line-height: 16px;
    font-weight: 700;
    padding: 20px 5px 20px 5px;
  }

  .header__icon-bar a {
    text-decoration: none;
  }

  .header__icon-bar {
    position: relative;
  }

  .header__icon-bar__private img {
    padding-right: 0.5em;
  }

  .header__icon-bar__private span {
    color: #6f6f6f;
    text-decoration: none;
    display: table-cell;
    vertical-align: middle;
    line-height: 21px;
  }

  .header__icon-bar__private span:hover {
    color: #c3ccd3;
  }

  .header__icon-bar__mobile-nav span {
    display: block;
    height: 3px;
    width: 30px;
    background-color: #00275f;
    margin-bottom: 5px;
  }

  .header__icon-bar__mobile-nav span:last-child {
    margin: 0;
  }

  @media (max-width: 767px) {
    .header-top-hp {
      display: block;
    }
    .header .container {
      display: block;
      align-items: center;
    }

    .header__icon-bar {
      margin-top: 8px;
    }
    .v-application .header__icon-bar__mobile-nav {
      display: inline-block;
    }
    .v-application .header__icon-bar__private span {
      display: none;
    }
    .header__icon-bar__private.fullw {
      display: none !important;
    }
  }

  .header__icon-bar__private.fullw {
    margin-top: 25px;
    float: right;
    display: inline-flex;
  }

  /*IRENE*/
  @media (min-width: 0px) and (max-width: 575px) {
    #footer-esempio .footer-logo-ghp {
      width: 100%;

      text-align: center;
    }

    #footer-esempio .footer-right-ghp {
      width: 100%;

      text-align: center;

      padding-top: 20px;

      font-size: 13px;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    #footer-esempio .footer-logo-ghp {
      width: 100px;
    }

    #footer-esempio .footer-right-ghp {
      width: calc(100% - 120px);
      padding: 0px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    #footer-esempio .footer-logo-ghp {
      width: 100px;
    }

    #footer-esempio .footer-right-ghp {
      width: calc(100% - 140px);
      padding: 0px;
    }
  }
</style>
